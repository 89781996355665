import {useContext, useEffect, useRef, useState} from "react";
import Loader from "../../components/addOns/loader";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import MainHeader from "../../components/addOns/headers/MainHeader";
import {
    faCircleCheck,
    faCopy,
    faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Card from "../../components/addOns/card";
import ValidPop from "../../components/addOns/popComponents/validPop";

function DepositsList() {

    const navigate = useNavigate();
    // Refs
    const firstNameRef = useRef("");
    const lastNameRef = useRef("");
    const depositRef = useRef("");
    const quoteRef = useRef("");
    const invoiceRef = useRef("");
    const [dateRange, setDateRange] = useState([]);

    // States
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('datedown');
    const [interlocutor, setInterlocutor] = useState([]);
    const [searchSelect, setSearchSelect] = useState("");
    const [searchReset, setSearchReset] = useState(false);
    const [stateUpdtate, setStateUpdtate] = useState(false);
    const [depositState, setDepositState] = useState("");
    const [showValidPop, setShowValidPop] = useState(false);
    const [depositId, setDepositId] = useState();

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);

    useEffect(() => {
        setLoading(true);
        if (filter) {
            getApiContext.getDepositsList(depositState, filter)
                .then(response => {
                    console.log(response);
                    setInterlocutor(response.listInterlocutors);
                    setDeposits(response.depositsList);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        } else if (!filter || !searchSelect) {
            getApiContext.getDepositsList()
            //axios.get(`${process.env.REACT_APP_DEPOSIT_LIST}`)
                .then(response => {
                    //console.log(response);
                    setInterlocutor(response.listInterlocutors);
                    setDeposits(response.depositsList);
                    // setPerPage(response.perPage);
                    // setNbPages(response.nbPages);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }
        setStateUpdtate(false)
        setSearchReset(false)
    }, [filter, searchReset, stateUpdtate, getApiContext, depositState, searchSelect]);

    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setDateRange([]);
        setSearchReset(true)
    }

    const contentObject = {
        ContentName: "ACOMPTE",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refAconmpte", title: "Référence Acompte", type: "text", ref: depositRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteRef },
            { name: "refFacture", title: "Référence Facture", type: "text", ref: invoiceRef },
            { name: "dateRange", title: "Date de création", type: "dateRangePicker", ref: dateRange },
        ]
    }

    const onSearchSubmit = (e) => {

        const searchData = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            depositRef: depositRef.current.value,
            quoteRef: quoteRef.current.value,
            invoiceRef: invoiceRef.current.value,
            interlocutor: searchSelect,
            startDate: dateRange[0] ? dateRange[0].toLocaleDateString().split('T')[0] : null,
            endDate: dateRange[1] ? dateRange[1].toLocaleDateString().split('T')[0] : null,
        }

        setLoading(true);
        e.preventDefault();
        console.log("searchData", searchData);
        postApiContext.postDepositsList(searchData)
        //axios.post(`${process.env.REACT_APP_DEPOSIT_LIST}`, searchData)
            .then(response => {
                // console.log(response);
                setDeposits(response.depositsList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const onSearchSelectChange = (e) => {
        setSearchSelect(e.target.value)
    }

    const changePerPage = () => {
        // setPerPage(e.target.value);
        // setCurrentPage(1);
    };

    const stateObject = [
        { valid: "", name: "Tous les acomptes" },
        { valid: 1, name: "Acomptes payés" },
        { valid: 0, name: "Acomptes impayés" }
    ];

    const handleOpenValidItem = (id) => {
        setShowValidPop(true);
        setDepositId(id);
    }

    const handleOpenCredit = () => {
        navigate(`/depositsList/deposit/${depositId}`)
    }

    const openEmptyDeposit = () => {
        navigate("/depositsList/deposit/")
    }

    const headerButtons = [
        {title: "Nouveau acompte", className: "", icon: faFileMedical, action: openEmptyDeposit, showByRoleId: true},
    ]

    const sortOptions = {
        options: [
            { value: "datedown", content: "Date décroissante" },
            { value: "dateup", content: "Date croissante" },
            { value: "refup", content: "Références croissantes" },
            { value: "refdown", content: "Références décroissantes" },
        ]
    }
    return (
        <>
            <MainHeader headerTitle={"ACOMPTES"} objectButtons={headerButtons}/>
            {loading && < Loader />}
            {showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop}
                          title={"Continuer vers cet avoir ?"}
                          desc={"Cet avoir est validé, voulez-vous vraiment la modifier ?"}
                          buttonsObject={[
                              {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
                              {text: "Continuer", className: "continueButton", action: handleOpenCredit},
                          ]}/>
            }
                <div className="listPage">
                    <div className="listPage__filterContainer">
                        <StateFilter setState={setDepositState} state={depositState} stateObject={stateObject}/>
                        <SearchComponent refresh={refresh}
                                         onSearchSelectChange={onSearchSelectChange}
                                         onSearchSubmit={onSearchSubmit}
                                         contentObject={contentObject}
                                         interlocutor={interlocutor}
                                         dateRange={dateRange}
                                         setDateRange={setDateRange}
                                         showFilter={true}
                        />
                        <SortByFilter setSort={setFilter} sortOptions={sortOptions}/>
                    </div>
                    {deposits && deposits.length > 0 && deposits.map((deposit, k) => {
                        const element = {
                            title: "Acompte n°",
                            id: deposit.id,
                            valid: deposit.valid,
                            state: deposit.state,
                            date: deposit.depositDate,
                            ref: deposit.ref,
                            subject: deposit.subject,
                            lastName: deposit.contact.lastName,
                            firstName: deposit.contact.firstName,
                            society: deposit.contact.society,
                            // totalHt: credit.totalHT,
                            depositRate: deposit.rate,
                            totalTtc: deposit.totalTtc,
                            directionPath: "/depositsList/deposit/",
                            initials: deposit.user.initials,
                            origin: deposit.invoice ? "Facture n°"+deposit.invoice.ref : deposit.quote ? "Devis n°"+deposit.quote.ref : "",
                        }
                        return (
                            <Card
                                key={k}
                                tableName={"deposit"}
                                setListRefresh={setSearchReset}
                                deleteEndPoint='c-deactive-deposit'
                                element={element}
                                otherButtonList={[
                                    {title: "Télécharger", icon: faDownload, className: "edit", action: () => console.log("hello")},
                                    {title: "Envoyer", icon: faPaperPlane, className: "delete", action: () => console.log("hello")},
                                    {title: "imprimer", icon: faPrint, className: "edit", action: () => console.log("hello")},
                                    {title: "dupliquer", icon: faCopy, className: "dup", action: (e) => console.log('ajouter fonction dup')},
                                ]}
                                stateButtonsList={[
                                    {title: "Payé", icon: faCircleCheck, color: "stateGreen", name: "paid"},
                                    {title: "Non Payé", icon: faCircleCheck, color: "stateOrange", name: "unpaid"},
                                    {title: "Annulé", icon: faCircleCheck, color: "stateRed", name: "canceled"},
                                ]}
                                handleOpenValidItem={handleOpenValidItem}
                                roleVerif={true}

                            />
                        )
                    })
                    }
                </div>
                <div className='pagination'>
                            <label>Éléments par page </label>
                            <select name='perPage' onChange={(e) => changePerPage(e)}>
                            {/* <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}> */}
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </select>
                            {/* <Pagination 
                                // postsPerPage={perPage}
                                // setCurrentPage={setCurrentPage}
                                // currentPage={currentPage}
                                // nbPages={nbPages}
                            />*/}
                </div>
        </>
    )

}

export default DepositsList;