import {
    faCircleCheck,
    faClipboardList,
    faCopy, faDownload,
    faFileMedical,
    faPaperPlane,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from 'react-router-dom';
import {GetApiContext} from "../../context/apiCalls/getApiCalls";
import {PostApiContext} from "../../context/apiCalls/postApiCalls";
import {useContext, useEffect, useRef, useState} from 'react';
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import MainHeader from '../../components/addOns/headers/MainHeader';
import Card from "../../components/addOns/card";
import StateFilter from "../../components/addOns/filterComponent/stateFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
import SortByFilter from "../../components/addOns/filterComponent/SortByFilter";
import Loader from "../../components/addOns/loader";
import ValidPop from "../../components/addOns/popComponents/validPop";
import {useAuthState} from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";

function InvoicesList() {
    const {roleId} = useAuthState();
    const roleList = ["1", "2", "3", "4", "5", "6"];
    const roleVerif = roleVerify(roleList, roleId)

    const getApiContext = useContext(GetApiContext);
    const postApiContext = useContext(PostApiContext);

    // refs
    const firstNameRef = useRef("");
    const lastNameRef = useRef("");
    const depositRef = useRef("");
    const invoiceRef = useRef("");
    const societyRef = useRef("");
    const [dateRange, setDateRange] = useState([]);

    let navigate = useNavigate();

    const [invoicesList, setInvoicesList] = useState();
    const [interlocutors, setInterlocutors] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [listRefresh, setListRefresh] = useState(false);
    const [perPage, setPerPage] = useState();
    const [nbPages, setNbPages] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [state, setState] = useState("all");
    const [sort, setSort] = useState('datedown');
    const [selectedInterlocutor, setSelectedInterlocutor] = useState();
    const [loading, setLoading] = useState(true);
    const [showValidPop, setShowValidPop] = useState(false);
    const [invoiceId, setInvoiceId] = useState();


    useEffect(() => {
        document.querySelector("body").style.overflow = showValidPop ? "hidden" : "auto";
    }, [showValidPop])
    useEffect(() => {
        if (roleVerif) {
            getApiContext.getInvoicesList(currentPage, perPage, state, sort).then(response => {
                setInvoicesList(response.lstInvoice)
                setInterlocutors(response.listInterlocutors)

                setPerPage(response.perPage);
                setNbPages(response.nbPages);
                setTotalTTC(response.totalAmonts?.total_ttc);
                setTotalHT(response.totalAmonts?.total_ht);
                setTotalVAT(response.totalAmonts?.total_vat);
                setLoading(false)
                setListRefresh(false)
            })
                .catch(error => {
                    console.log(error);
                })
        }else {
            console.log("role not verified")
        }
        // console.log("refresh")
    }, [getApiContext,currentPage, perPage, listRefresh, state, sort, roleVerif]);


    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };

    const openEmptyInvoice = () => {
        navigate("/invoicesList/invoice/")
    }

    const duplicateInvoice = (e) => {
        const dupId = e.target.parentNode.parentNode.id;
        getApiContext.getInvoice("", dupId).then(response => {
            const invoice = response.invoice;
            navigate(`/invoicesList/invoice/${invoice?.id}`);
        });
    }

    const openDeliveriesList = () => {
        navigate("deliveriesList");
    }

    const objectButtons = [
        {title: "Bons de livraison", className: "deliveryButton", icon: faClipboardList, action: openDeliveriesList, showByRoleId: false},
        {title: "Nouvelle facture", className: "", icon: faFileMedical, action: openEmptyInvoice, showByRoleId: true},
    ]

    const otherButtonList = [
        {title: "Télécharger", icon: faDownload, action: openEmptyInvoice},
        {title: "Envoyer", icon: faPaperPlane, action: openEmptyInvoice},
        {title: "imprimer", icon: faPrint, action: openEmptyInvoice},
        {title: "dupliquer", icon: faCopy, action: duplicateInvoice},
    ]

    const stateButtonsList = [
        {title: "Finalisé", icon: faCircleCheck, action: openEmptyInvoice, color: "stateGreen", name: "validated"},
        {title: "En cours", icon: faCircleCheck, action: openEmptyInvoice, color: "stateOrange", name: "in-progress"},
        {title: "Annulé", icon: faCircleCheck, action: openEmptyInvoice, color: "stateRed", name: "canceled"},
    ]

    const stateObject = [
        {valid: "all", name: "toutes les factures"},
        {valid: "validated", name: "vos factures finalisés"},
        {valid: "in-progress", name: "vos factures en cours"},
        {valid: "canceled", name: "vos factures annulés"},
    ];
    const refresh = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setSort("refup");
        setDateRange([]);
        setSelectedInterlocutor(null);
        setListRefresh(true);
    }

    const contentObject = {
        ContentName: "facture",
        Content: [
            {name: "firstName", title: "Prénom", type: "text", ref: firstNameRef},
            {name: "lastName", title: "Nom", type: "text", ref: lastNameRef},
            {name: "refAconmpte", title: "Référence Acompte", type: "text", ref: depositRef},
            {name: "refFacture", title: "Référence Facture", type: "text", ref: invoiceRef},
            {name: "dateRange", title: "Date de création", type: "dateRangePicker", ref: dateRange},
            {name: "society", title: "Société", type: "text", ref: societyRef},
        ]
    }

    const onSearchSubmit = (e) => {
        const searchData = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            depositRef: depositRef.current.value,
            refInvoice: invoiceRef.current.value,
            society: societyRef.current.value,
            startDate: dateRange && dateRange[0] ? dateRange[0].toLocaleDateString().split('T')[0] : null,
            endDate: dateRange && dateRange[1] ? dateRange[1].toLocaleDateString().split('T')[0] : null,
            interlocuteur: selectedInterlocutor,
        }

        console.log("searchData", searchData)

        console.log(searchData)
        e.preventDefault();
        postApiContext.postInvoicesList(searchData).then(response => {
                console.log(response);
                setInvoicesList(response.lstInvoice);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onSearchSelectChange = (e) => {
        setSelectedInterlocutor(e.target.value)
    }

    const sortOptions = {
        options: [
            {value: "datedown", content: "Date décroissante"},
            {value: "dateup", content: "Date croissante"},
            {value: "refup", content: "Références croissantes"},
            {value: "refdown", content: "Références décroissantes"},
        ]
    }
    const handleOpenValidItem = (id) => {
        setShowValidPop(true)
        setInvoiceId(id)
    }
    const handleOpenInvoice = () => {
        navigate(`/invoicesList/invoice/${invoiceId}`)
    }
    const buttonsObject = [
        {text: "Retour", className: "backButton", action: (e) => setShowValidPop(false)},
        {text: "Continuer", className: "continueButton", action: handleOpenInvoice},
    ]
    return (
        <>
            <MainHeader
                headerTitle={"FACTURES"}
                objectButtons={objectButtons}
                roleVerif={roleVerif}
            />

            {
                showValidPop &&
                <ValidPop bgcAction={(e) => setShowValidPop(false)} showValidPop={showValidPop}
                          title={"Continuer vers cette facture ?"}
                          desc={"Cette facture est validé, voulez-vous vraiment la modifier ?"}
                          buttonsObject={buttonsObject}/>
            }
            {
                loading &&
                <Loader/>
            }
            <div className="listPage">
                <div className="listPage__filterContainer">
                    <StateFilter state={state} setState={setState} stateObject={stateObject}/>
                    <SearchComponent onSearchSelectChange={onSearchSelectChange} onSearchSubmit={onSearchSubmit}
                                     contentObject={contentObject} refresh={refresh} interlocutor={interlocutors}
                                     dateRange={dateRange} setDateRange={setDateRange}
                    />
                    <SortByFilter setSort={setSort} sortOptions={sortOptions}/>
                </div>
                {invoicesList && invoicesList.map((invoice, k) => {
                    const element = {
                        id: invoice.invoiceId,
                        valid: invoice.invoiceValid,
                        state: invoice.invoiceState,
                        date: invoice.invoiceDate,
                        ref: invoice.invoiceRef,
                        subject: invoice.subject,
                        lastName: invoice.societyContactLastName,
                        firstName: invoice.societyContactFirstName,
                        society: invoice.societyContactSociety,
                        totalHt: invoice.invoiceTotalHt,
                        totalTtc: invoice.invoiceTotalTtc,
                        directionPath: "/invoicesList/invoice/",
                        initials: invoice.userInitials,
                    }
                    return (
                        <Card key={k} roleVerif={roleVerif} handleOpenValidItem={handleOpenValidItem} tableName={"invoice"}
                              setListRefresh={setListRefresh}
                              deleteEndPoint={"c-deactive-invoice"} stateButtonsList={stateButtonsList}
                              otherButtonList={otherButtonList} element={element}/>
                    )
                })
                }
            </div>
            <TotalAmonts
                totalTTC={totalTTC}
                totalHT={totalHT}
                totalVAT={totalVAT}
            />
            {/*!loading &&*/
                (
                    <div className='pagination'>
                        <label>Éléments par page </label>
                        <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="300">300</option>
                            <option value="500">500</option>
                        </select>
                        <Pagination
                            postsPerPage={perPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            nbPages={nbPages}
                        />
                    </div>
                )}
            <a href="#!" alt="" className="add-button"><i className="fa-solid fa-plus"></i></a>
        </>
    );
}

export default InvoicesList;
