import {createContext} from "react";

export const GetApiContext = createContext(null);

export default function GetApiProvider(props) {
    return (
        <GetApiContext.Provider
            value={{
                getInvoice: API.getInvoice,
                getInvoicesList: API.getInvoicesList,
                getContactsList: API.getContactsList,
                getInterlocutorsList: API.getInterlocutorsList,
                getVatList: API.getVatList,
                getUnitsList: API.getUnitsList,
                transferQuoteToInvoice: API.transferQuoteToInvoice,
                getQuotesList: API.getQuotesList,
                getContact: API.getContact,
                getQuote: API.getQuote,
                getMargin: API.getMargin,
                getDeposit: API.getDeposit,
                getDashboardData : API.getDashboardData,
                contactQuoteListGet : API.contactQuoteListGet,
                contactInvoiceListGet : API.contactInvoiceListGet,
                getSettings: API.getSettings,
                getDepositsList: API.getDepositsList,
                getCatalogsList: API.getCatalogsList,
                getOrderList: API.getOrderList,
                getOrder : API.getOrder,
                getCreditList: API.getCreditList,
                getCredit: API.getCredit,
            }}>
            {props.children}
        </GetApiContext.Provider>
    );
}
let token = localStorage.getItem("acces_token");

let headers = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        ...(token && { "Authorization": "Bearer " + token }),
    },
}
const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

const API = {
    getInvoice: async (id, dup) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-invoice&id=${id}&dup=${dup}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getInvoicesList: async (currentPage, perPage, state, sort) => {
        updateHeaders();
      try {
            let response = await fetch(`${capboxApi}c-invoices-list&page=${currentPage}&nombre=${perPage}&state=${state}&sort=${sort}`, headers);
            return handleApiResponse(response);
      } catch (e) {
            console.log(e);
        }
    },
    getContactsList: async (contactState) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-contacts-list-pop&state=${contactState}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getInterlocutorsList: async () => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-interlocutor-list`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },

    getVatList: async () => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-vat-list`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getUnitsList: async () => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-unit-list`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    transferQuoteToInvoice: async (quoteId) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-invoice&quote=${quoteId}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getInvoiceByQuote: async (quoteId) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-invoice&quote=${quoteId}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getContact: async (id) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-contact-pop&id=${id}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getQuotesList: async (sort, quoteState, currentPage, perPage) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-quotes-list&sort=${sort}&state=${quoteState}&page=${currentPage}&nombre=${perPage}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getQuote: async (quoteId, dup) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-quote&id=${quoteId}&dup=${dup}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getMargin: async (id) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-margin&id=${id}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getDeposit: async (id, idQuote, idInvoice) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-deposit&id=${id}&idQuote=${idQuote}&idInvoice=${idInvoice}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getDashboardData: async (selectedDate, rate, selectedMonth) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-dashboard&date=${selectedDate}&rate=${rate}&month=${selectedMonth}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    contactQuoteListGet: async (id) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-contact&id=${id}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    contactInvoiceListGet: async (id) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-contact&id=${id}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getSettings: async (updateToken) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-settings`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getDepositsList: async (valid, filter) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-deposits-list&valid=${valid}&sort=${filter}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getCatalogsList: async () => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-catalogs-list`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getOrderList: async (state, sort) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-order-forms-list&state=${state}&sort=${sort}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getOrder: async (id, dup) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-order-form&id=${id}&dup=${dup}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getCreditList: async (creditState, sort, currentPage, perPage) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-credits-list&state=${creditState}&sort=${sort}&currentPage=${currentPage}&parPage=${perPage}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
    getCredit: async (id, dup) => {
        updateHeaders();
        try {
            let response = await fetch(`${capboxApi}c-credit&id=${id}&dup=${dup}`, headers);
            return handleApiResponse(response);
        } catch (e) {
            console.log(e);
        }
    },
}

const handleApiResponse = async (response) => {
    try {
        const text = await response.text();
        // console.log("Réponse brute:", text);

        let token, responseData;
        if (text.includes('}{')) { // Si la réponse contient plusieurs objets JSON (token & data)
            const [tokenText, responseDataText] = text.split(/(?<=\})(?=\{)/);
            try {
                token = JSON.parse(tokenText);
                responseData = JSON.parse(responseDataText);
            } catch (e) {
                console.error('Erreur lors du parsing JSON:', e);
                return;
            }
            if (token && token.token) {
                localStorage.setItem("acces_token", token.token);
            }
        } else { // Si la réponse contient un seul objet JSON (data)
            try {
                responseData = JSON.parse(text);
            } catch (e) {
                console.error('Erreur lors du parsing JSON:', e);
                return;
            }
        }

        //console.log('Response Data:', responseData);
        return responseData;
    } catch (error) {
        console.error('Erreur lors du traitement de la réponse API:', error);
    }
};

function updateHeaders() {
    token = localStorage.getItem("acces_token");
    headers = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ...(token && {"Authorization": "Bearer " + token}),
        },
    }
}
