import ContactSearchButton from "../../../addOns/buttonComponent/ContactSearchButton";
import {ContactPopContext} from "../../../../context/ContactPopContext";
import ContactList from "../../../../routes/contact/contactList";
import {useContext} from "react";
import ReactDOM from "react-dom";

export default function ContactInfosPanel({contact, handleClickModal, onSelectContact, setContacts}) {

  const contactPopContext = useContext(ContactPopContext);
    return (
        <div className="contactInfosPanel">
          {contactPopContext.showContactPopup && ReactDOM.createPortal(
              <>
                <div onClick={() => contactPopContext.setShowContactPopup(false)} className="bluredDiv"></div>
                <div style={{ width: '90%', height: '90vh', backgroundColor: "#fff", zIndex: 99999, position: "absolute", margin: "5vh 5%", overflow: "auto", top: '0' }}>
                  <ContactList selectMode={true} onSelectContact={onSelectContact} setContacts={setContacts}/>
                </div>
              </>,
              document.getElementsByClassName('main')[0] // Rendu dans le conteneur principal
          )}
            <div className="contactInfosPanel__content">
                <h3 className="contactInfosPanel__content_title">Nom du client :</h3>
                <p className="contactInfosPanel__content_name">{contact?.society} - {contact?.firstName} {contact?.lastName}</p>
                <hr className="contactInfosPanel__content_line"/>
            </div>
            <ContactSearchButton handleClickModal={handleClickModal}/>
        </div>
    )
}